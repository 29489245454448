import "./App.css";

//import { Icon } from "@iconify/react";

function App() {
  return (
    <div className="container">
      <h2>Jose David Rivas</h2>
      <h2>Full Stack Web Developer</h2>
    </div>
  );
}

export default App;
